import { ApiCriteria } from "@/api/kpi/types/apiCriteria";
import { ApiKpiPeriod, ApiKpiPeriodMapper } from "@/api/kpi/types/apiKpiPeriod";
import { KpiMainInfo } from "@/store/kpi/modules/kpi/modules/mainInfo/types/kpiMainInfo";
import { ApiStaffDepartmentPosition } from "@/api/kpi/types/apiStaffDepartmentPosition";
import { parseIds } from "@/store/kpi/helpers/staffDepartmentPosition";

export interface ApiAddKpiRequest {
	title: string;
	criterias: ApiCriteria[];
	staffDepartmentPositions: ApiStaffDepartmentPosition[];
	periods: ApiKpiPeriod[];
	isRegular: boolean;
	methodology: string;
}

export class ApiAddKpiRequestHelper {
	static map(source: KpiMainInfo): ApiAddKpiRequest {
		return {
			title: source.title,
			criterias: source.criterias,
			isRegular: source.isRegular,
			methodology: source.methodology,
			staffDepartmentPositions: source.staffDepartmentPositions.map(x => parseIds(x)),
			periods: source.periods.map(x => ApiKpiPeriodMapper.map(x))
		};
	}
	
	static getEmpty(): ApiAddKpiRequest {
		return {
			title: "",
			methodology: "",
			criterias: [],
			staffDepartmentPositions: [],
			periods: [],
			isRegular: false
		};
	}
}
