<template>
	<v-card class="kpi-card pa-9 pb-6 my-4" v-if="isInitialized">
		<v-form :readonly="isKpiMainInfoFormSaving || isFormReadonly"
				:disabled="isFormDisabled"
				ref="kpiMainInfoForm"
				v-model="isMainInfoFormValid">
			<frp-form-card-blocks class="d-flex flex-column mb-6">
				<frp-form-card-block-row>
					<frp-form-card-block-col style="flex: 0 0 30%">
						<span>{{ $t("details.titles.kpi") }}</span>
					</frp-form-card-block-col>
					<frp-form-card-block-col>
						<frp-textarea v-model="kpi"
									  required
									  :disabled="isReadMode || isKpiMainInfoFormSaving"
									  :placeholder="$t('fields.kpi.placeholder')">
						</frp-textarea>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
				
				<frp-form-card-block-row>
					<frp-form-card-block-col style="flex: 0 0 30%">
						<span>{{ $t("details.titles.calculationMethodology") }}</span>
					</frp-form-card-block-col>
					<frp-form-card-block-col>
						<frp-textarea v-model="methodology"
									  :disabled="isReadMode || isKpiMainInfoFormSaving"
									  :placeholder="$t('fields.methodology.placeholder')">
						</frp-textarea>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
				
				<frp-form-card-block-row>
					<frp-form-card-block-col style="flex: 0 0 30%" class="d-flex align-center">
						<span>{{ $t("details.titles.criterias") }}</span>
						
						<frp-btn v-if="isEditMode"
								 color="blue"
								 class="ml-1"
								 icon
								 :disabled="isKpiMainInfoFormSaving"
								 @click="addEditableItemCriteria">
							<v-icon color="primary">mdi-plus-circle-outline</v-icon>
						</frp-btn>
					</frp-form-card-block-col>
					<frp-form-card-block-col :class="isEditMode ? 'pr-2' : 'pr-3'">
						<div class="d-flex flex-column" style="gap: 12px">
							<div class="d-flex align-center justify-space-between"
								 v-for="(criteria, index) in criterias" :key="`${criteria.id}-${index}`">
								<frp-text-field v-model="criteria.title"
												required
												:disabled="isReadMode || isKpiMainInfoFormSaving"
												height="57"
												autocomplete="off"
												style="width: 100%;"
												:placeholder="$t('fields.criteria.placeholder')"
												hide-details>
								</frp-text-field>
								
								<frp-btn v-if="isEditMode"
										 without-padding
										 height="28"
										 small
										 icon
										 color="blue"
										 dark
										 :disabled="criterias.length <= 1 || isFormDisabled || isKpiMainInfoFormSaving"
										 @click="deleteEditableItemCriteria({ index, id: criteria.id })">
									<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
								</frp-btn>
							</div>
						</div>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
				
				<frp-form-card-block-row>
					<frp-form-card-block-col style="flex: 0 0 30%" class="d-flex align-center">
						<span>{{ $t("details.titles.constantIndicator") }}</span>
					</frp-form-card-block-col>
					<frp-form-card-block-col class="d-flex align-center">
						<v-switch v-model="isRegularValue"
								  :disabled="isKpiMainInfoFormSaving || isReadMode"
								  color="blue">
						</v-switch>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
				
				<frp-form-card-block-row>
					<frp-form-card-block-col style="flex: 0 0 30%">
						<span>{{ $t("details.titles.applicableToPeriods") }}</span>
						
						<frp-btn v-if="editableItemPeriods.length && isEditMode"
								 class="text-none pl-1 pr-2 mt-2"
								 max-dense
								 outlined
								 :disabled="isKpiMainInfoFormSaving || isRegularTruly"
								 :color="colors.blue.base"
								 @click="isAddPeriodsDialogOpened = true">
							<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
							{{ $t("buttons.addPeriod") }}
						</frp-btn>
					</frp-form-card-block-col>
					<frp-form-card-block-col>
						<frp-btn v-if="!editableItemPeriods.length && isEditMode"
								 class="text-none pl-1 pr-2"
								 max-dense
								 outlined
								 :disabled="isKpiMainInfoFormSaving || isRegularTruly"
								 :color="colors.blue.base"
								 @click="isAddPeriodsDialogOpened = true">
							<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
							{{ $t("buttons.addPeriod") }}
						</frp-btn>
						
						<v-simple-table v-if="editableItemPeriods.length"
										class="kpi-periods-table"
										:class="{'kpi-periods-table__disabled': isReadMode || isRegularTruly}">
							<template v-slot:default>
								<thead>
								<tr>
									<th class="text-left" width="20%">
										{{ $t("tables.year") }}
									</th>
									<th class="text-left" width="80%">
										{{ $t("tables.periods") }}
									</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="period in formattedEditableItemPeriods"
									:key="period.year">
									<td>
										<span v-if="isReadMode || isRegularTruly" class="primary--text text--darken-1">{{ period.year }}</span>
										
										<frp-text-btn v-else
													  class="pa-0 loan-text-btn text-decoration-underline"
													  style="min-width: fit-content;"
													  color="blue"
													  :disabled="isKpiMainInfoFormSaving"
													  @click="deleteEditableItemFullPeriod({ year: period.year })">
											<span>{{ period.year }}</span>
										</frp-text-btn>
									</td>
									
									<td class="d-flex align-center" style="gap: 13px">
										<v-chip active-class="primary--text blue lighten-3"
												class="kpi-chip kpi-periods-table__chip primary--text blue lighten-3"
												:disabled="isReadMode || isRegularTruly || isKpiMainInfoFormSaving"
												v-for="(quarter, index) in period.quarters"
												:key="`${quarter}-${index}`"
												:value="quarter"
												@click="deleteEditableItemPeriodsByQuarter({ year: period.year, quarter })">
											{{ formatKpiQuarterValueByText(quarter) }}
										</v-chip>
									</td>
								</tr>
								</tbody>
							</template>
						</v-simple-table>
					
					</frp-form-card-block-col>
				</frp-form-card-block-row>
				
				<frp-form-card-block-row>
					<frp-form-card-block-col style="flex: 0 0 30%" class="d-flex align-center">
						<span>{{ $t("details.titles.staffEmployees") }}</span>
					</frp-form-card-block-col>
					<frp-form-card-block-col>
						<frp-autocomplete v-model="staffUsersIds"
										  :items="staffUsers"
										  :disabled="isReadMode || isKpiMainInfoFormSaving || !editableItemPeriods.length"
										  :loading="isStaffUsersLoading"
										  class="kpi-multiple-chips-autocomplete"
										  multiple
										  chips
										  deletable-chips
										  menu-max-width="800"
										  item-text="text"
										  item-value="ids"
										  color="blue"
										  hide-details
										  required
										  :placeholder="$t('fields.staffEmployee.placeholder')">
						</frp-autocomplete>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
			</frp-form-card-blocks>
		</v-form>
		
		<div class="d-flex mx-n3">
			<v-spacer></v-spacer>
			
			<frp-alert v-if="!isActive" 
					   class="d-block mb-md-0"
					   color="warning"
					   :text="$t('alerts.info.kpiDeleted')">
			</frp-alert>
			
			<template v-else>
				<template v-if="isEditMode">
					<frp-btn elevation="0"
							 :disabled="(!isStateContainsUnsavedChanges() && isCreateMode) || isKpiMainInfoFormSaving"
							 @click="handleCancelBtn">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					
					<frp-btn elevation="0"
							 :class="{'px-8' : isCreateMode}"
							 :loading="isKpiMainInfoFormSaving"
							 :disabled="!isMainInfoFormValid || !editableItemPeriods.length || !isStateContainsUnsavedChanges()"
							 dark
							 color="blue"
							 @click="onFormSubmit">
						<span v-if="isCreateMode">{{ $t("buttons.next") }}</span>
						<span v-else>{{ $t("buttons.save") }}</span>
					</frp-btn>
				</template>
				
				<frp-btn v-if="canDeleteKpi && isReadMode"
						 elevation="0"
						 class="px-3"
						 color="secondary"
						 :disabled="isEditMode || kpiCriteriasInfoMode === KpiCriteriasModeTypeEnum.EDIT"
						 @click="isDeleteKpiDialogOpened = true">
					{{ $t("buttons.remove") }}
				</frp-btn>
				
				<frp-btn v-if="canEditKpi && isReadMode"
						 elevation="0"
						 class="px-3"
						 color="blue"
						 dark
						 :disabled="kpiCriteriasInfoMode === KpiCriteriasModeTypeEnum.EDIT"
						 @click="setMode(KpiMainInfoModeTypeEnum.EDIT)">
					{{ $t("buttons.edit") }}
				</frp-btn>
				
				<frp-confirm-dialog v-if="isKpiUnsavedChangesDialogOpened"
									submit-btn-color="blue"
									submit-btn-dark
									@submit="handleCancelFormSubmit"
									@cancel="isKpiUnsavedChangesDialogOpened = false"
									:title="$t('dialogs.confirmOperationWithUnsavedChanges.title')"
									:description="$t('dialogs.confirmOperationWithUnsavedChanges.description')">
				</frp-confirm-dialog>
			</template>
		</div>
		
		<frp-dialog v-model="isAddPeriodsDialogOpened"
					:title="$t('dialogs.addKpiPeriods.title')"
					persistent
					max-width="457">
			<template #content="{ onIntersect }">
				<v-form v-intersect="onIntersect" ref="addPeriodsForm" v-model="isAddPeriodsFormValid">
					<frp-autocomplete :label="$t('fields.year.label')"
									  item-text="text"
									  item-value="value"
									  v-model="selectedPeriodYear"
									  :items="formattedPeriodYears"
									  color="blue"
									  required
									  :placeholder="$t('fields.year.placeholder')">
					</frp-autocomplete>
					
					<v-chip-group v-model="selectedPeriodQuarters"
								  class="kpi-chip-group"
								  multiple
								  active-class="primary--text blue lighten-3">
						<v-chip active-class="primary--text blue lighten-3"
								class="kpi-chip"
								v-for="item in filteredQuarters"
								:key="item.value"
								:disabled="!selectedPeriodYear"
								:value="item.value">
							{{ item.text }}
						</v-chip>
					</v-chip-group>
				</v-form>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 @click="closeAddPeriodsDialog"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 :color="colors.blue.base"
						 :disabled="!isAddPeriodsFormValid || !selectedPeriodQuarters.length"
						 @click="handleAddPeriods">
					<span class="white--text">{{ $t("buttons.save") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
		
		<frp-dialog v-model="isDeleteKpiDialogOpened"
					:title="$t('dialogs.deleteKpi.title')"
					max-width="583">
			<template #content="{ onIntersect }">
				<span v-intersect="onIntersect" class="primary--text text-body-2">
					{{ $t("dialogs.deleteKpi.warning") }}
				</span>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 @click="isDeleteKpiDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="secondary"
						 :loading="isKpiDeleting"
						 @click="handleDeleteKpi">
					{{ $t("buttons.remove") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</v-card>
	<kpi-kpi-main-info-loader v-else></kpi-kpi-main-info-loader>
</template>

<script>

import FrpAlert from "@/components/alerts/FrpAlert.vue";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpSwitch from "@/components/fields/FrpSwitch.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BodFormCardBlock from "@/components/markup/BodFormCardBlock.vue";
import FrpFormCardBlock from "@/components/markup/FrpFormCardBlock.vue";
import FrpFormCardBlockCol from "@/components/markup/FrpFormCardBlockCol.vue";
import FrpFormCardBlockRow from "@/components/markup/FrpFormCardBlockRow.vue";
import FrpFormCardBlocks from "@/components/markup/FrpFormCardBlocks.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/kpi/routes";
import { KpiCriteriasModeTypeEnum } from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/KpiCriteriasModeTypeEnum";
import { getterTypes, mutationTypes, actionTypes } from "@/store/kpi/modules/kpi/modules/mainInfo/types";
import { namespace } from "@/store/kpi/modules/kpi/modules/mainInfo";
import { KpiMainInfoModeTypeEnum } from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoModeTypeEnum";
import { getterTypes as kpiHeadModuleGetterTypes, actionTypes as kpiHeadModuleActionTypes } from "@/store/kpi/modules/kpi/types";
import storeManager from "@/store/manager";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";
import { formatKpiQuarterValueByText } from "@/utils/formatting";
import KpiKpiMainInfoLoader from "@/views/kpi/kpi/sections/mainInfo/KpiKpiMainInfoLoader.vue";
import { isEqual, without } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations, mapActions, mapGetters } = createNamespacedHelpers(namespace);
const kpiHeadModuleHelpers = createNamespacedHelpers(storeManager.kpi.kpi.namespace);
const kpiCriteriasInfoModuleHelpers = createNamespacedHelpers(storeManager.kpi.kpi.criteriasInfo.namespace);

export default {
	mixins: [formMixin, storeModuleBasedPage, authorizationMixin, colorsMixin],
	data() {
		return {
			RouteNames,
			KpiMainInfoModeTypeEnum,
			KpiCriteriasModeTypeEnum,
			KpiQuarterEnum,
			namespace,
			formatKpiQuarterValueByText,
			isAddPeriodsDialogOpened: false,
			isAddPeriodsFormValid: false,
			selectedPeriodYear: 0,
			selectedPeriodQuarters: [],
			defaultQuarters: [
				{ text: i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.I}`), value: 1 },
				{ text: i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.II}`), value: 2 },
				{ text: i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.III}`), value: 3 },
				{ text: i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.IV}`), value: 4 },
				{ text: i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.YEAR}`), value: null }
			],
			formattedQuarters: [],
			isKpiUnsavedChangesDialogOpened: false,
			isDeleteKpiDialogOpened: false
		};
	},
	computed: {
		...kpiHeadModuleHelpers.mapGetters({
			staffUsers: kpiHeadModuleGetterTypes.staffUsers,
			periodYears: kpiHeadModuleGetterTypes.periodYears,
			canEditKpi: kpiHeadModuleGetterTypes.canEditKpi
		}),
		...kpiHeadModuleHelpers.mapState({
			periods: state => state.periods,
			kpiPermissions: state => state.permissions,
			isStaffUsersLoading: state => state.isStaffUsersLoading
		}),
		...kpiCriteriasInfoModuleHelpers.mapState({
			kpiCriteriasInfoMode: state => state.mode
		}),
		...mapGetters({
			isReadMode: getterTypes.isReadMode,
			isEditMode: getterTypes.isEditMode,
			formattedEditableItemPeriods: getterTypes.formattedEditableItemPeriods
		}),
		...mapState({
			state: state => state,
			mode: state => state.mode,
			editableItem: state => state.editableItem,
			isActive: state => state.editableItem.isActive,
			editableItemPeriods: state => state.editableItem.periods,
			criterias: state => state.editableItem.criterias,
			isInitialized: state => state.isInitialized,
			isKpiMainInfoFormSaving: state => state.isKpiMainInfoFormSaving,
			isKpiDeleting: state => state.isKpiDeleting,
			isKpiMainInfoFormValid: state => state.isKpiMainInfoFormValid
		}),
		canDeleteKpi() {
			return this.kpiPermissions.includes(this.Permissions.KPI_KPI_DELETE);
		},
		filteredQuarters() {
			return this.selectedPeriodYear ? this.formattedQuarters : this.defaultQuarters;
		},
		formattedPeriodYears() {
			return this.periodYears.map(x => ({ text: x, value: x }));
		},
		isRegularTruly() {
			return this.editableItem.isRegular;
		},
		isMainInfoFormValid: {
			get() {
				return this.isKpiMainInfoFormValid;
			},
			set(value) {
				this.setIsKpiMainInfoFormValid(value);
			}
		},
		isRegularValue: {
			get() {
				return this.editableItem.isRegular;
			},
			set(value) {
				this.setRegularIndicator(value);
			}
		},
		kpi: {
			get() {
				return this.editableItem.title;
			},
			set(value) {
				this.setEditableItemTitle(value);
			}
		},
		methodology: {
			get() {
				return this.editableItem.methodology;
			},
			set(value) {
				this.setEditableItemMethodology(value);
			}
		},
		staffUsersIds: {
			get() {
				return this.editableItem.staffDepartmentPositions;
			},
			set(value) {
				this.setEditableItemStaffIds(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setIsKpiMainInfoFormValid: mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_VALID,
			setEditableItemTitle: mutationTypes.SET_EDITABLE_ITEM_TITLE,
			setEditableItemMethodology: mutationTypes.SET_EDITABLE_ITEM_METHODOLOGY,
			setEditableItemStaffIds: mutationTypes.SET_EDITABLE_ITEM_STAFF_IDS,
			setMode: mutationTypes.SET_MODE
		}),
		...kpiHeadModuleHelpers.mapActions({
			fetchStaffUsers: kpiHeadModuleActionTypes.fetchStaffUsers
		}),
		...mapActions({
			save: actionTypes.save,
			deleteKpi: actionTypes.deleteKpi,
			addEditableItemCriteria: actionTypes.addEditableItemCriteria,
			deleteEditableItemCriteria: actionTypes.deleteEditableItemCriteria,
			addEditableItemPeriods: actionTypes.addEditableItemPeriods,
			deleteEditableItemFullPeriod: actionTypes.deleteEditableItemFullPeriod,
			deleteEditableItemPeriodsByQuarter: actionTypes.deleteEditableItemPeriodsByQuarter,
			setRegularIndicator: actionTypes.setRegularIndicator
		}),
		handleCancelBtn() {
			if(this.isStateContainsUnsavedChanges())
				this.isKpiUnsavedChangesDialogOpened = true;
			else if(!this.isCreateMode)
				this.setMode(KpiMainInfoModeTypeEnum.READ);
		},
		async handleDeleteKpi() {
			await this.deleteKpi();
			this.isDeleteKpiDialogOpened = false;
		},
		handleCancelFormSubmit() {
			this.isKpiUnsavedChangesDialogOpened = false;
			this.onCancelFormSubmit();
			
			if(!this.isCreateMode)
				this.setMode(KpiMainInfoModeTypeEnum.READ);
		},
		async onCancelFormSubmit() {
			await this.cancelChanges();
			this.$nextTick(() => {
				if(!this.isResetValidationDisabled)
					this.$refs.kpiMainInfoForm.resetValidation();
			});
		},
		getFilteredQuartersByYear(year, periods) {
			const filteredPeriodsByYear = periods.filter(x => x.year === year);
			
			return without(filteredPeriodsByYear.map(x => x.quarter), undefined);
		},
		getFilteredAndFormattedQuarters(quartersArray) {
			return this.defaultQuarters.filter(x => quartersArray.includes(x.value));
		},
		closeAddPeriodsDialog() {
			this.selectedPeriodYear = 0;
			this.$refs.addPeriodsForm.resetValidation();
			this.isAddPeriodsDialogOpened = false;
		},
		handleAddPeriods() {
			this.addEditableItemPeriods({ year: this.selectedPeriodYear, quarters: this.selectedPeriodQuarters });
			
			this.closeAddPeriodsDialog();
		},
		async onFormSubmit() {
			if(this.$refs.kpiMainInfoForm.validate())
				await this.save({ id: this.$route.params.id });
		}
	},
	watch: {
		selectedPeriodYear: {
			handler(year) {
				this.selectedPeriodQuarters = [];
				if(!year) return;
				
				this.selectedPeriodQuarters = this.getFilteredQuartersByYear(year, this.editableItemPeriods);
				
				const filteredQuarters = this.getFilteredQuartersByYear(year, this.periods);
				this.formattedQuarters = this.getFilteredAndFormattedQuarters(filteredQuarters);
			},
			immediate: true
		},
		editableItemPeriods: {
			async handler(value, oldValue) {
				if(value.length && !isEqual(value, oldValue)) {
					await this.fetchStaffUsers(value);
					
					this.staffUsersIds = this.staffUsersIds.filter(x => this.staffUsers.some(y => x === y.ids));
				}
			},
			immediate: true
		}
	},
	components: {
		FrpAlert,
		KpiKpiMainInfoLoader,
		FrpTextBtn,
		FrpDialog,
		FrpTextField,
		FrpAutocomplete,
		FrpConfirmDialog,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow, FrpFormCardBlock, FrpSwitch, FrpTextarea, FrpBtn, FrpIcon, FrpFormCardBlocks, BodFormCardBlock, FrpCard
	}
};
</script>

<style lang="scss">
.kpi-periods-table {
  border: 0.4px solid var(--v-grey-base);

  th {
	height: 44px !important;
  }

  thead {
	margin-bottom: 5px !important;
  }

  thead, th {
	border-bottom: 0.4px solid var(--v-grey-base) !important;
  }

  tbody, td {
	border-bottom: none !important;
	border-top: none !important;

	margin: 5px 0px !important;
  }

  &.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
	background: transparent !important;
  }

  &__chip {
	cursor: pointer !important;
  }

  &__chip.v-chip--disabled {
	opacity: 1;
  }

  &__disabled {
	background-color: var(--v-white-lighten1) !important;
	border: 0.4px solid var(--v-white-lighten1);
  }

  &__disabled, &__chip {
	cursor: default;
  }
}
</style>
