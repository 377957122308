import { ApiKpi } from "@/api/kpi/types/apiKpi";
import { KpiMainInfo } from "@/store/kpi/modules/kpi/modules/mainInfo/types/kpiMainInfo";
import { CriteriaHelper } from "@/store/kpi/modules/kpi/types/criteria";
import { KpiPeriodMapper } from "@/types/kpi/KpiPeriod";
import { StaffDepartmentPositionMapper } from "@/types/kpi/staffDepartmentPosition";
import { uniq } from "lodash";

export class KpiMainInfoMapper {
	mapToKpiMainInfo(source: ApiKpi): KpiMainInfo {
		return {
			...source,
			methodology: source.methodology ?? "",
			periods: source.periods.map(x => KpiPeriodMapper.map(x)),
			criterias: source.criterias.sort((a, b) => a.sort - b.sort).map((x, index) => CriteriaHelper.map(x, index)),
			staffDepartmentPositions: uniq(source.staffKpis.map(x => StaffDepartmentPositionMapper.mapFromApiUser(x.staff).ids))
		};
	}
}