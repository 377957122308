import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/kpi/modules/kpi/modules/criteriasInfo/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import { KpiController } from "@/api/kpi";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import { resolveAction, resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import SubscribersManager from "@/store/manager/subscribersManager";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import router from "@/router/kpi";
import KpiCriteriasInfoState from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasInfoState";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import KpiState from "@/store/kpi/modules/kpi/types/kpiState";
import storeManager from "@/store/manager";
import { KpiCriteriasMapper } from "@/store/kpi/modules/kpi/modules/criteriasInfo/mapper";
import { KpiCriteriasModeTypeEnum } from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/KpiCriteriasModeTypeEnum";
import { cloneDeep, groupBy, isNumber, last, uniq } from "lodash";
import { KpiCriteriasItem } from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasItem";
import KpiCriteriasFilter from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasFilter";
import KpiCriteriasRouteQueryService from "@/store/kpi/modules/kpi/modules/criteriasInfo/services/kpiCriteriasRouteQueryService";
import KpiCriteriasRouteQuery from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasRouteQuery";
import RouteMixinBuilder from "@/store/shared/route";
import { RouteNames } from "@/router/kpi/routes";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import routeTypes from "@/store/shared/route/types";
import { ApiStaffKpi } from "@/api/kpi/types/apiStaffKpi";
import { StaffKpi } from "@/types/kpi/staffKpi";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { ApiKpiPeriod, ApiKpiPeriodMapper } from "@/api/kpi/types/apiKpiPeriod";
import { ApiUpdateStaffCriteriasItem } from "@/api/kpi/types/apiUpdateStaffCriteriasItem";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import BankAccountApplicationState from "@/store/bar/modules/bankAccountApplication/types/bankAccountApplicationState";
import KpiMainInfoState from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoState";
import userTypes from "@/store/modules/user/types";
import kpiMainInfoTypes from "@/store/kpi/modules/kpi/modules/mainInfo/types";
import { KpiPeriod, KpiPeriodMapper } from "@/types/kpi/KpiPeriod";
import { actionTypes as rootActionTypes } from "@/store/kpi/types";
import { getYear } from "date-fns";
import { getIds } from "@/store/kpi/helpers/staffDepartmentPosition";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);

const defaultRouteQuery = new KpiCriteriasRouteQuery(0);

const routeQueryService = new KpiCriteriasRouteQueryService(defaultRouteQuery);

const routeMixin = (new RouteMixinBuilder<KpiCriteriasInfoState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableItems"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new KpiCriteriasInfoState(
			new ListingModel<KpiCriteriasItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			routeMixin.state(),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

let subscribersManager: SubscribersManager<KpiCriteriasInfoState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<KpiCriteriasInfoState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<KpiCriteriasInfoState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(namespace, mutationTypes.SET_MODE):
		{
			const { listing: { items } } = state;
			
			if(mutation.payload === KpiCriteriasModeTypeEnum.EDIT) {
				subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_EDITABLE_ITEMS), cloneDeep(items));
				subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_STATE_SNAPSHOT), stateSnapshotKeys.LAST_SAVED);
			}
			
			break;
		}
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
		{
			if((mutation.payload.from.name === mutation.payload.to.name) && !mutation.payload.to.query.year) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.reconstituteRoute));
			} else if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		}
		// Фильтрация на фронте
		case resolveMutation(namespace, mutationTypes.SET_FRONT_FILTER_YEAR):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			break;
		default:
			break;
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<KpiCriteriasInfoState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	...listingMixin.getters,
	[getterTypes.isReadMode]: state => {
		return state.mode === KpiCriteriasModeTypeEnum.READ;
	},
	[getterTypes.isEditMode]: state => {
		return state.mode === KpiCriteriasModeTypeEnum.EDIT;
	},
	[getterTypes.kpiStaffKpisPeriodYears]: state => {
		return uniq(state.staffKpisPeriods.map((x: KpiPeriod) => x.year));
	}
};

const actions = <ActionTree<KpiCriteriasInfoState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	...routeMixin.actions,
	...listingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...pagingMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, rootState }) {
		await dispatch(actionTypes.initializeBase);
		
		commit(mutationTypes.SET_ID, router.currentRoute.params.id);
		
		const { kpi } = resolveNestedState<KpiState>(rootState, storeManager.kpi.kpi.namespace);
		
		commit(mutationTypes.SET_STAFF_KPIS_PERIODS, kpi.staffKpis.map(x => KpiPeriodMapper.map(x.period)));
		
		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		
		const groupedStaffKpiItems = await dispatch(actionTypes.groupByStaffKpiItems, kpi.staffKpis);
		
		commit(mutationTypes.SET_LISTING_ITEMS, groupedStaffKpiItems);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.groupByStaffKpiItems]({}, staffKpis): Promise<KpiCriteriasItem[]> {
		// группировка для вывода информации в таблице весов критериев. Группируем по кварталу и году + у каждого года и квартала есть группировка по определенному сотруднику, чтобы в него вложить критерии и веса под каждый критерий
		const mappedItems = staffKpis.map((x: ApiStaffKpi) => KpiCriteriasMapper.mapToStaffKpi(x));
		const formattedItemsWithGroup = mappedItems.map((x: StaffKpi) => {
			const ids = getIds(x.staff.id, x.staff.department.id, x.staff.position.id);
			return { ...x, group: `${x.period.year}${x.period.quarter}${ids}` };
		});
		const grouped = Object.values(groupBy(formattedItemsWithGroup, "group"));
		return grouped.map(x => {
			return {
				hasReport: x[0].hasReport,
				staff: x[0].staff,
				period: x[0].period,
				criterias: x.map(y => y.criteria),
				weights: x.map(y => {
					return {
						value: isNumber(y.weight) ? y.weight.toString() : "",
						criteriaId: y.criteria.id,
						criteriaSort: y.criteria.sort,
						criteriaTitle: y.criteria.title
					};
				}).sort((a, b) => a.criteriaSort - b.criteriaSort),
				group: `${x[0].period.quarter}-${x[0].period.year}`,
				ids: getIds(x[0].staff.id, x[0].staff.department.id, x[0].staff.position.id)
			};
		}).sort((a, b) => a.period.quarter - b.period.quarter);
	},
	async [actionTypes.save]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_KPI_CRITERIAS_FORM_SAVING, true);
		
		try {
			const payload: ApiUpdateStaffCriteriasItem[] = [];
			
			// Проверка на то, что если значения weight из state.editableItems изменяется, и не совпадает со значением weight из state.listing.items, то на бэк маппится и отправляется измененное значение, а не весь массив
			state.editableItems.forEach((editableItem, index) => {
				const foundedListingItem = state.listing.items.find(x => {
					return x.ids === editableItem.ids && x.group === editableItem.group;
				});
				
				if(!foundedListingItem) return;
				
				foundedListingItem.weights.forEach((obj1, index) => {
					const obj2 = editableItem.weights[index];
					if(obj1.value !== obj2.value) {
						payload.push({
							staffId: foundedListingItem.staff.id,
							departmentId: foundedListingItem.staff.department.id,
							positionId: foundedListingItem.staff.position.id,
							period: ApiKpiPeriodMapper.map(foundedListingItem.period),
							criteriaId: obj2.criteriaId,
							weight: obj2.value ? +obj2.value : 0
						});
					}
				});
			});
			
			await kpiController.updateStaffWeights(state.id, payload);
			
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
			commit(mutationTypes.SET_LISTING_ITEMS, state.editableItems);
			commit(mutationTypes.SET_MODE, KpiCriteriasModeTypeEnum.READ);
			
			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_KPI_CRITERIAS_FORM_SAVING, false);
		}
	},
	async [actionTypes.resetFrontFilter]({ rootState, getters, commit, dispatch, state }) {
		// Если у нас в выбранных kpiStaffKpisPeriodYears есть текущий год, то проставляем его, если текущего нет, то проставляем следующий, если он есть. Если и его нет, то выбираем самый последний год из выбранных
		const kpiPeriodsLastYear = last(getters.kpiStaffKpisPeriodYears);
		const currentYear: number = getYear(new Date());
		const nextYear: number = currentYear + 1;
		
		const kpiPeriodYearsIncludesCurrentYear: boolean = getters.kpiStaffKpisPeriodYears.includes(currentYear);
		const kpiPeriodYearsIncludesNextYear: boolean = getters.kpiStaffKpisPeriodYears.includes(nextYear);
		
		if(kpiPeriodYearsIncludesCurrentYear) {
			return commit(mutationTypes.SET_FRONT_FILTER_YEAR, currentYear);
		} else if(!kpiPeriodYearsIncludesCurrentYear && kpiPeriodYearsIncludesNextYear) {
			return commit(mutationTypes.SET_FRONT_FILTER_YEAR, nextYear);
		} else {
			return commit(mutationTypes.SET_FRONT_FILTER_YEAR, kpiPeriodsLastYear);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, getters, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);
		
		if(!router.currentRoute.query.year)
			await dispatch(actionTypes.resetFrontFilter);
		
		if(state.filter.year !== routeQuery.year)
			commit(mutationTypes.SET_FRONT_FILTER_YEAR, routeQuery.year);
		
		const stringifiedPeriods = getters.kpiStaffKpisPeriodYears.map((x: number) => x.toString());
		
		if(!stringifiedPeriods.includes(router.currentRoute.query.year))
			await dispatch(actionTypes.resetFrontFilter);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
		
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.KPI,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.KPI,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<KpiCriteriasInfoState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...listingMixin.mutations,
	...routeMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...pagingMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = value;
	},
	[mutationTypes.SET_STAFF_KPIS_PERIODS](state, value) {
		state.staffKpisPeriods = value;
	},
	[mutationTypes.SET_FRONT_FILTER_YEAR](state, value) {
		state.filter.year = value;
	},
	[mutationTypes.SET_IS_KPI_CRITERIAS_FORM_SAVING](state, value) {
		state.isKpiCriteriasFormSaving = value;
	},
	[mutationTypes.SET_IS_KPI_CRITERIAS_FORM_VALID](state, value) {
		state.isKpiCriteriasFormValid = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const kpiCriteriasInfoModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default kpiCriteriasInfoModule;
