import { Criteria } from "@/store/kpi/modules/kpi/types/criteria";
import { KpiPeriod } from "@/types/kpi/KpiPeriod";
import { ApiStaffDepartmentPosition } from "@/api/kpi/types/apiStaffDepartmentPosition";

export interface KpiMainInfo {
	title: string;
	methodology: string;
	criterias: Criteria[];
	staffDepartmentPositions: string[];
	periods: KpiPeriod[];
	isRegular: boolean;
	isActive: boolean;
}

export class KpiMainInfoHelper {
	static getEmpty(): KpiMainInfo {
		return {
			title: "",
			methodology: "",
			criterias: [],
			staffDepartmentPositions: [],
			periods: [],
			isRegular: false,
			isActive: true
		};
	}
}
