import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";
import { ApiStaffDepartmentPosition } from "@/api/kpi/types/apiStaffDepartmentPosition";
import { getIds } from "@/store/kpi/helpers/staffDepartmentPosition";

export interface StaffDepartmentPosition {
	staffId: string;
	departmentId: string;
	positionId: string;
	ids: string;
}

export class StaffDepartmentPositionMapper {
	static mapFromApi(source: ApiStaffDepartmentPosition): StaffDepartmentPosition {
		return {
			...source,
			ids: getIds(source.staffId, source.departmentId, source.positionId)
		};
	}
	
	static mapFromApiUser(source: ApiKpiUser): StaffDepartmentPosition {
		return {
			staffId: source.id,
			departmentId: source.department.id,
			positionId: source.position.id,
			ids: `${source.id}_${source.department.id}_${source.position.id}`
		};
	}
}
